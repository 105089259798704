import { render, staticRenderFns } from "./HelpDetail.vue?vue&type=template&id=47a2265e&"
import script from "./HelpDetail.vue?vue&type=script&lang=ts&"
export * from "./HelpDetail.vue?vue&type=script&lang=ts&"
import style0 from "./HelpDetail.vue?vue&type=style&index=0&id=47a2265e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports