




import Vue from 'vue'

const props = {
  topic: {
    type: Object,
    required: true
  },
  search: {
    type: String,
    default: null
  }
}

export default Vue.extend({
  name: "HelpDetail",
  props,
  computed: {
    regexp(): RegExp {
      let flags = 'ig'
      let pattern = `(${this.search})`
      return new RegExp(pattern, flags)
    },
    highlightedSearchTerm(): string {
      if (this.search) {
        return this.topic.body.replace(this.regexp, (foundString: string) => {
          return `<span class="yellow black--text font-weight-bold">${foundString}</span>`
        })
      }
      return this.topic.body
    }
  }
})
