























































































































































































import Vue from 'vue'
import HelpData from '@/assets/help/c2-help.json'
import HelpDetail from '@/components/Help/HelpDetail.vue'
import HelpSection from '@/components/Help/HelpSection.vue'

const props = {
  buttonColor: {
    type: String,
    default: 'grey darken-3'
  },
  width: {
    type: Number,
    default: 300
  },
  height: {
    type: Number,
    default: 200
  },
  suggestionCount: {
    type: Number,
    default: 5
  }
}

enum EHelpStates {
  HELP_MAIN,
  HELP_SECTION,
  HELP_DETAIL
}

enum EHelpType {
  CUSTOM = 'CUSTOM'
}

interface IHelpSection {
  body: string
  guid: string
  header: string
  topic: string
}

export interface IHeroSection {
  title: string
  page: string
  sections: IHelpSection[]
}

export default Vue.extend({
  name: 'HelpButton',
  components: { HelpSection, HelpDetail },
  props,
  data: (): any => {
    return {
      fab: false,
      menu: false,
      search: '',
      showSuggestions: true,
      helpData: HelpData,
      selectedItem: null,
      heroSectionId: null,
      isSuggested: false,
      suggestedTopics: null,
      helpState: EHelpStates.HELP_MAIN,
      states: {
        help_main: EHelpStates.HELP_MAIN,
        help_section: EHelpStates.HELP_SECTION,
        help_detail: EHelpStates.HELP_DETAIL
      }
    }
  },
  created() {
    this.getSuggestedTopics()
  },
  mounted() {
    this.$emitter.on('help:toggle', () => {
      this.menu = !this.menu
      this.resetHelpButton()
    })
  },
  computed: {
    useQuestionMarkButton() {
      return this.helpState !== this.states.help_main
    },
    currentView(): object {
      return this.$route
    },
    heroSection(): IHeroSection | null {
      if (this.heroSectionId) {
        if (this.heroSectionId === EHelpType.CUSTOM) {
          return {
            title: 'custom',
            page: EHelpType.CUSTOM,
            sections: this.filteredItems
          }
        }
        return this.filteredItems.find((section: IHeroSection) => {
          return section.page === this.heroSectionId
        })
      }
      return null
    },
    heroSectionItems(): IHelpSection[] {
      if (this.heroSection) {
        return this.heroSection.sections.slice(1)
      }
      return []
    },
    heroSectionHeaders(): IHelpSection | null {
      if (this.heroSection) {
        return this.heroSection.sections[0]
      }
      return null
    },
    title(): string | null {
      if (this.heroSection) {
        return this.heroSection.title.replace(/[^A-Za-z ]+/g, '').trim()
      }
      return null
    },
    backButtonText(): string {
      if (this.isState(EHelpStates.HELP_SECTION)) {
        return this.title
      }
      if (this.isState(EHelpStates.HELP_DETAIL)) {
        return this.selectedItem.header
      }
      return ''
    },
    regexp(): RegExp {
      let flags = 'ig'

      let pattern = `(${this.search})`
      return new RegExp(pattern, flags)
    },
    toolbarText(): string {
      if (this.search) {
        return 'Help > Search'
      }
      let text = 'Help'
      if (this.isSuggested) {
        console.log(this.suggestedTopics)
        text = `${text} > ${this.suggestedTopics.title}`
      } else if (
        this.isState([EHelpStates.HELP_SECTION, EHelpStates.HELP_DETAIL])
      ) {
        text = `${text} > ${this.title}`
      }
      return text.toUpperCase()
    },
    filteredItems(): IHelpSection[] {
      // const regex = new RegExp(`(${this.search})`, 'ig')
      if (this.search === '' || this.search === null) return this.helpData
      return this.helpData
        .map((item: IHeroSection) => {
          let filteredSections = []
          for (let x = 1; x < item.sections.length; x++) {
            if (
              item.sections[x].header.match(this.regexp) ||
              item.sections[x].body.match(this.regexp)
            ) {
              filteredSections.push(item.sections[x])
            }
          }
          return filteredSections
        })
        .flat()
    },
    showBackButton(): boolean {
      return !(
        this.isState(EHelpStates.HELP_MAIN) ||
        (this.isState(EHelpStates.HELP_SECTION) && !!this.search)
      )
    },
    showSearchbar(): boolean {
      if (this.isState(EHelpStates.HELP_MAIN)) return true
      return !!(this.isState(EHelpStates.HELP_SECTION) && this.search)
    }
  },
  methods: {
    goToMainHelpPage() {
      this.helpState = EHelpStates.HELP_MAIN
      this.selectedItem = null
      this.heroSectionId = null
    },
    isState(statesToCheck: EHelpStates | EHelpStates[]): boolean {
      if (Array.isArray(statesToCheck)) {
        return statesToCheck.includes(this.helpState)
      } else {
        return [statesToCheck].includes(this.helpState)
      }
    },
    setHeroSection(item: IHeroSection): void {
      this.helpState = EHelpStates.HELP_SECTION
      this.heroSectionId = item.page
      if (this.heroSectionItems.length === 1) {
        // skip the section index listing
        this.setSelectedItem(this.heroSectionItems[0])
      }
    },
    setSelectedItem(item: IHelpSection) {
      this.helpState = EHelpStates.HELP_DETAIL
      this.selectedItem = item
    },
    suggestedTopicClicked(item: IHelpSection) {
      this.helpState = EHelpStates.HELP_DETAIL
      this.selectedItem = item
      this.isSuggested = true
    },
    async resetHelpButton(): Promise<void> {
      this.selectedItem = null
      this.heroSectionId = null
      this.helpState = EHelpStates.HELP_MAIN
      this.showSuggestions = true
      this.search = ''
      this.getSuggestedTopics()
    },
    fabClicked(): void {
      this.fab = !this.fab
      this.menu = false
      if (!this.fab) {
        setTimeout(() => {
          this.resetHelpButton()
        }, 500)
      }
    },
    backClicked(): void {
      if (this.heroSectionItems.length === 1) {
        // skip the section index listing
        this.heroSectionId = null
        this.selectedItem = null
        this.helpState = EHelpStates.HELP_MAIN
      }
      if (this.isState(EHelpStates.HELP_SECTION)) {
        this.heroSectionId = null
        this.helpState = EHelpStates.HELP_MAIN
      }
      if (this.isState(EHelpStates.HELP_DETAIL)) {
        this.selectedItem = null
        if (this.isSuggested) {
          this.helpState = EHelpStates.HELP_MAIN
          this.isSuggested = false
        } else {
          this.helpState = EHelpStates.HELP_SECTION
        }
      }
    },
    searchCleared(): void {
      this.heroSectionId = null
      this.helpState = EHelpStates.HELP_MAIN
    },
    refreshSuggestions(): void {
      let title: string | null = null
      if (this.currentView.path === '/') {
        title = 'Dashboard'
      }
      if (
        this.currentView.path === '/installations' ||
        this.currentView.path.includes('/sensors/rf') ||
        this.currentView.path.includes('/sensors/radar')
      ) {
        title = 'Installation Settings'
      }
      if (this.currentView.path === '/stream') {
        title = 'Cameras'
      }
      if (this.currentView.path === '/zones') {
        title = 'Maps and Zones'
      }
      if (this.currentView.path === '/report') {
        title = 'Analytics'
      }
      if (this.currentView.path === '/plans') {
        title = 'Plans'
      }
      if (this.currentView.path === '/sites/new') {
        title = 'System Settings'
      }

      if (title) {
        let topics = this.helpData.find((item: IHeroSection) => {
          return item.title === title
        })

        if (topics.sections) {
          topics = topics.sections.slice(1)
          const shuffledTopics = topics.sort(() => 0.5 - Math.random())
          this.suggestedTopics = {
            title,
            page: this.helpData.find((item: IHeroSection) => {
              return item.title === title
            }).page,
            sections: shuffledTopics.slice(0, this.suggestionCount)
          }
        } else {
          this.suggestedTopics = {
            page: null,
            title: null,
            sections: []
          }
        }
      } else {
        this.suggestedTopics = {
          page: null,
          title: null,
          sections: []
        }
      }
    },
    getSuggestedTopics(): void {
      this.refreshSuggestions()
      if (this.heroSectionId) {
        this.helpState = EHelpStates.HELP_SECTION
      } else {
        this.helpState = EHelpStates.HELP_MAIN
      }
    }
  },
  watch: {
    heroSectionId: {
      handler: function(val) {
        this.showSuggestions = !val
      },
      immediate: true
    },
    search: {
      handler: function(): void {
        if (this.search) {
          this.heroSectionId = EHelpType.CUSTOM
        } else {
          this.heroSectionId = null
          this.helpState = EHelpStates.HELP_MAIN
        }
      },
      immediate: true
    },
    currentView: {
      handler: function(): void {
        this.menu = false
        this.selectedItem = null
        this.fab = false
        this.showSuggestions = true
        this.getSuggestedTopics()
      },
      immediate: false
    }
  }
})
