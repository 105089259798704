















import Vue from 'vue'
import {IHeroSection} from "@/components/Help/HelpButton.vue"

const props = {
  items: {
    type: Array,
    required: true
  },
  showDivider: {
    type: Boolean,
    default: false
  },
  keyName: {
    type: String,
    required: true
  },
  textColor: {
    type: String,
    default: 'white--text'
  }
}
export default Vue.extend({
  name: "HelpSection",
  props,
  data: (): any => {
    return {
      item: null
    }
  },
  computed: {
    selectedItem: {
      get(): IHeroSection {
        return this.item
      },
      set(val: IHeroSection) {
        this.item = val
        this.$emit('onSelect', this.item)
      }
    }
  }
})
